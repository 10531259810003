.FakeMatrix {
  height: 100%;
  display: flex;
  justify-content: space-between;
  padding: 30px;
  width: 100%;
  gap: 50px;
}

.FakeMatrix .m-ctnr {
  /* border: 1px solid lime; */
  width: 80%;
  margin-top: 50px;
  margin-bottom: 70px;
}
.FakeMatrix .ucs {
  border: 4px solid var(--lightblue-transparent);
  border-radius: 30px;
  margin: 50px 0;
  padding: 30px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 18px;

}
.FakeMatrix .ucs.dim{
  opacity: .4 ;
}
.FakeMatrix .ucs .Title {
  margin: 0;
  padding: 0;
}
.FakeMatrix .ucs .uc {
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 40px;
  line-height: 1em;
}



.FakeMatrix .ucs .uc.inactive{ opacity: 0.4; }
.FakeMatrix .ucs .uc.inactive .Matrix-action{ box-shadow: none; }
.FakeMatrix .ucs .uc.hidden { opacity: 0; }

.FakeMatrix .ucs.invert .uc.inactive { opacity: 1; }
.FakeMatrix .ucs.invert .uc.inactive .Matrix-action{  box-shadow: 0 0 20px var(--lightblue);}
.FakeMatrix .ucs.invert .uc:not(.inactive){opacity: 0.4; }
.FakeMatrix .ucs.invert .uc:not(.inactive) .Matrix-action{ box-shadow: none;}






.ucs.dim .Matrix-action{ box-shadow: none;}
.ActionsMatrix.dimAllButAbscisse .Matrix-action{ box-shadow: none;}
.ActionsMatrix.dimAllButOrdonnee .Matrix-action{ box-shadow: none;}

.controller {
  font-size: 0.8em;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 8;
}