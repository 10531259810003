.Square .Square-base {
  fill-opacity: 0.2;
  stroke-dasharray: 2, 2;
  stroke-width: 0.52917;
}

.Square.strategy .Square-base   { stroke: #40bfff; fill: #40bfff; }
.Square.tech .Square-base { stroke: #ee8aff; fill: #ee8aff; }
.Square.people .Square-base { stroke: #ffe552; fill: #ffe552; }
.Square.teal .Square-base   { stroke: #00b8bc; fill: #00b8bc; }

.Square .highlighted .Brick   {
  stroke: #fff;
  stroke-width: 10px;
  stroke-linecap: round;
  stroke-linejoin: round;
  paint-order: stroke;
  position: relative;
}
