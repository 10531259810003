.Action{
  border: 2px solid  #0C396D;
  padding: 20px;
  margin: 10px;

  max-width: 970px;


  display: flex;
  flex-direction: column;
  gap: 10px;

  cursor: pointer;
  position: relative;
}


.Action .Action-top {
  display: flex;
  align-items: center;
  gap: 40px;

}


.Action .Action-chevron{
  display: block;
  background: transparent;
  border: 1px solid #FFF;

  min-width: 56px;
  max-width: 56px;
  min-height: 56px;
  max-height: 56px;

  border-radius: 10px;
  background: url("../../../../images/chevron_down.png") no-repeat center center;
}

.Action h2 {
  font-size: 21pt;
  font-weight: normal;
  text-transform: none;
}

.Action .Action-effects {
  display: flex;
  gap: 10px;

  /* border: 1px solid  red; */

}
.Action .Action-description {
  font-size: 19pt;
  color: #08EFFF;
}


.Action.selected{
  border: 5px solid  #38FF7E;
  box-shadow: 0 0 10px #38FF7E, inset 0 0 10px #38FF7E;
}


.Action.selected .Action-chevron{ display: none; }
.Action.selected h2{ font-size: 25pt; margin: 0; margin-bottom: 10px; }
/* .Action.selected h2 strong{ display: none; } */



.Action.prev-selected{
  /*
  color: #777;
  box-shadow: inset 0 0 10px #777;
  */
  cursor: default;
  opacity: 0.5;
}
.Action.prev-selected .Action-chevron{
  background-image: url("../../../../images/check.png");
  border-radius: 50%;

}


.Action.disabled{
  opacity: 0.3;
  cursor: no-drop;
}

.Action .Action-effects {
  display: flex;

  justify-content: space-around;

}
.Action .Action-effect {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;

  /* border: 1px solid  red; */
}

.Action .Action-effects .Action-effect-title {
  text-align: center;
  max-width: 200px;

  font-size: 18pt;
}
.Action .Action-effects svg {
  /* border: 1px solid  red; */
  min-width: 0;
  width: 120px;
  height: 80px;

  max-width: none;
}

.Action .Action-effects .talent svg {
  width: 80px;
  height: 120px;
}