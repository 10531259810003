.Title {
  text-transform: uppercase;
  color: #08EFFF;
  text-align: center;
  font-size: 50pt;
  line-height: 50pt;
  filter: drop-shadow(0 0 5px#08EFFF);
  padding: 30px;
}

.Title b,
.Title strong{
  font-size: 45pt;
  display: block;
  color: transparent;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #08EFFF;


}
