.ScreenMoment {
  display: flex;
  height: 100%;
}

.screen.ScreenMoment .col1{
  min-width: 420px;

  display: flex;
  justify-content: center;
  align-items: center;


}

.ScreenMoment.matrix .col3{ width: 600px; }

.ScreenMoment.matrix .col2{
  padding: 20px 40px;
  padding-bottom: 0;
  /* border: 1px solid lime; */
  overflow: visible;
}

.ScreenMoment .col2{
  height: 100%;


  display: flex;
  flex-direction: column;
  overflow: hidden;


}

.ScreenMoment .float-left{
  margin-right: 10px;
  float: left;
  /* display: flex;
  align-items: center; */
}
.ScreenMoment h1{
  flex-grow: 1;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 38pt;
  margin: 0;
  margin-bottom: 10px;
}

.ScreenMoment .moment-sumup {
  /* border: 1px solid  red; */
  margin: 10px 0;
  font-size: 22pt;
}

.ScreenMoment .moment-actions-ctnr {
  position: relative;
  height: 100%;
  flex-grow: 1;
  overflow: hidden;

  display: flex;
  flex-direction: column;
}
.ScreenMoment .moment-actions {
  overflow-y: auto;
  flex-grow: 1;
}

.ScreenMoment .budget h2{
  text-transform: uppercase;
  font-weight: normal;
}
.ScreenMoment .budget-circle{
  background: #174D77;
  background: linear-gradient(to bottom, #174D771f, #174D7799);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 45pt;

  margin: 0 auto;

  width: 128px;
  height: 128px;
  border-radius: 50%;

  position: relative;
}
.ScreenMoment .budget-circle::after{
  content: "";
  position: absolute;
  top: 8px;
  left: 8px;
  right: 8px;
  bottom: 8px;
  border-radius: 50%;
  border: 2px solid #08EFFF;
}

.ScreenMoment .budget .budget-current{ display: block; font-size: 35pt;}
.ScreenMoment .budget .budget-total{
  font-size: 20pt;
  border-top: 2px solid #FFF;
}


.ScreenMoment .ScreenMoment-next-button {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  /* gap: 40px; */
}

.ScreenMoment .ScreenMoment-next-button .ScreenMoment-nb-selected{
  font-size: 30px;
  text-transform: uppercase;
  color: var(--lightblue);
  filter: drop-shadow(0 0 5px #08EFFF);
  margin-right: 80px;

}

.ScreenMoment .ScreenMoment-next-button .ScreenMoment-nb-selected strong{
  display: block;
  text-align: center;
  font-size: 90px;
  font-weight: normal;
}

.ScreenMoment .ScreenMoment-next-button .NeonButton{
  flex-grow: 1;
}