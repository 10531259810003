.EbitCircle .EbitCircle-rings{ fill:#08eeff; }
.EbitCircle .EbitCircle-rings .dimmed { opacity: .15; }

.EbitCircle .EbitCircle-stroke {
  fill: none;
  stroke: #08eeff;

  stroke-dasharray: 0.630047, 6.30047;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
  stroke-width: 0.63005;
}
.EbitCircle .EbitCircle-stroke :nth-child(2){
  stroke-width: 1.3575;
}
.EbitCircle .EbitCircle-bubble {
  fill: url("#bubble-gradient");
  opacity: 0.8;

}

@keyframes ebit-blink {
  0% { opacity: 0.8 ;}
  50% { opacity: .2 ;}
}
.EbitCircle.ebit-blink .EbitCircle-bubble {
  animation: ebit-blink 1s linear infinite;
}
