.SvgDashboard .SvgDashboard-texts{
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

}


.SvgDashboard-texts-ebit {
  position: absolute;
  top: 8%;
  right: 20%;
  text-align: center;

}
.SvgDashboard-texts-ebit .value{ font-size: 60pt;}
.SvgDashboard-texts-ebit .legend{
  color: var(--lightblue);
  font-size: 25pt;
}

.SvgDashboard-texts-usecases {
  position: absolute;
  font-size: 30pt;

  top: 45%;
  right: 75%; /* depuis right, pour qu'il s'étende vers la gauche si jamais */
}

.SvgDashboard-texts-talents {
  text-align: center;
  position: absolute;
  top: 74%;
  right: 77%;
  /* border: 1px solid  red; */
}
.SvgDashboard-texts-talents .talents-l1{
  font-size: 25pt;

}
.SvgDashboard-texts-talents .talents-l2{
  font-size: 24pt;
  text-transform: uppercase;
  color: var(--talents);
}

.SvgDashboard-texts-enablers {
  position: absolute;
  text-align: center;

  display: flex;
  align-items: center;
  gap: 10px;
}
.SvgDashboard-texts-enablers img {
  max-width: 70px;
  height: auto;
  max-height: 70px;
}

.SvgDashboard-texts-enablers .enablers-l1{
  font-size: 24pt;
  text-transform: uppercase;
}
.SvgDashboard-texts-enablers .enablers-l2{ font-size: 25pt; }

.SvgDashboard-texts-enablers.pwow { left:45%; bottom: 2%; flex-direction: row-reverse; }
.SvgDashboard-texts-enablers.pwow .enablers-l1 { color: var(--enablers-people); }

.SvgDashboard-texts-enablers.tech {
  /* right: 5%; bottom: 19%; */
  top: 74%;
  right: 77%;
}
.SvgDashboard-texts-enablers.tech .enablers-l1{ color: var(--enablers-tech); }

.SvgDashboard-texts-enablers.strat { right: 15%; top: 35%; }
.SvgDashboard-texts-enablers.strat .enablers-l1{ color: var(--enablers-strategy); }
