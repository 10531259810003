.SvgDashboard{
  position: absolute;
  right: 60px;
  bottom: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.SvgDashboard svg {
  width: 1400px;
  height: 980px;
}
