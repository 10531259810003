.ScreenStars .ScreenStars-score{
  font-size: 50pt;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  flex-grow: 1;

  margin-top: 30px;

}


.ScreenStars .ScreenStars-score span{
  display: flex;
  gap: 30px
}


.ScreenStars .ScreenStars-score span:last-of-type{
  margin-top: -30px;
}


.ScreenStars .ScreenStars-explanation {
  /* border: 1px solid  red; */
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ScreenStars .ScreenStars-dld-desc{
  font-size: 24pt;
  text-align: center;
  margin-bottom: 40px;
  /* color: var(--lightblue); */
}