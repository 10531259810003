.Cylinder.clickable {  cursor: pointer; }
.Cylinder.clickable:hover{ filter: brightness(1.2); }

.Cylinder .Cylinder-base{
  fill: #2b0000;
  fill-opacity: 0.3;
}
.Cylinder image { pointer-events: none; }

.Cylinder .Cylinder-side { fill:url(#gradient-neutral); }

.Cylinder .Cylinder-top{ fill: #009EBB; }
.Cylinder .Cylinder-side-icons {
  fill: #FFF;
  opacity: 0.6;
  pointer-events: none;

}
.Cylinder .Cylinder-stage0 { fill:#009EBB; }

.Cylinder-beam-ctnr { pointer-events: none; }

.Cylinder .Cylinder-stage0.RED { fill:#ff6e6e; }
.Cylinder .Cylinder-stage0.GREEN { fill:#4CFF95; }

.Cylinder .Cylinder-stage1.RED .Cylinder-side,
.Cylinder .Cylinder-stage2.RED .Cylinder-side{ fill:url(#gradient-error); }

.Cylinder .Cylinder-stage1.GREEN .Cylinder-side,
.Cylinder .Cylinder-stage2.GREEN .Cylinder-side{ fill:url(#gradient-success); }

.Cylinder .Cylinder-stage1.GREEN .Cylinder-top,
.Cylinder .Cylinder-stage2.GREEN .Cylinder-top{ fill:#4CFF95; }

.Cylinder .Cylinder-stage1.RED .Cylinder-top,
.Cylinder .Cylinder-stage2.RED .Cylinder-top{ fill:#ff6e6e; }



.Cylinder .Cylinder-beam{
  fill: url(#gradient-beam);
  fill-opacity: ".46241";
  stroke-dasharray: "3.99999, 3.99999";
  stroke-linejoin: "round";
  stroke-width: "2";
}

.Cylinder .Cylinder-star{
  transform: translate(0, 25px);
  animation: updown linear 4s infinite;
}

@keyframes updown {
  0% { transform: translate(0, 25px); }
  50% { transform: translate(0, 30px); }
  100% { transform: translate(0, 25px); }
}

.Cylinder.wireframe .Cylinder-side,
.Cylinder.wireframe .Cylinder-base,
.Cylinder.wireframe .Cylinder-top {
  fill: #090924 !important;
  stroke: #aaa  !important;
  stroke-width: 1px  !important;
}
.Cylinder.wireframe image {display: none;}
.Cylinder.wireframe .Cylinder-side-icons {display: none;}

.Cylinder.highlighted > *{
  stroke: #FFF;
  stroke-width: 10px;
  paint-order: stroke;
  stroke-linecap: round;
  stroke-linejoin: round;
}
