.ScreenMomentSelection {
  padding: 0;
  display: flex;
}

.ScreenMomentSelection .col2 .FadedScroll {
  padding-top: 30px;
}

.ScreenMomentSelection.screen .col3{
  max-width: 200px;
  min-width: 200px;
}