.ScreenHome {
  overflow: hidden;
}
.ScreenHome .col2{
  display: flex;
  justify-content: center;
  align-items: center;

}
.ScreenHome form {
  margin-top: 90px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
}
.ScreenHome form input {
  padding: 20px;
  font-size: 40px;
  border-radius: 15px;
}
.ScreenHome .error {
  color: #FF4F6F;
  font-size: 25px;
}