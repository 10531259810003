.PdfViewer {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.PdfViewer .PdfViewer-bottom {
  text-align: center;
}


.MediasGallery .PdfViewer .MediasGallery-prev,
.MediasGallery .PdfViewer .MediasGallery-next {
  position: static;
  display: inline-block;
  margin: 5px 10px;
}