.Personnage {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 4;
  background: transparent;
  /* pointer-events: none; */

  /* background: rgba(0, 0, 0, 0.30); */
  /* background: rgba(60, 228, 18, 0.61); */


  transition: opacity 350ms;
}




.Personnage.hidden { opacity: 0; }

.Personnage.visible { opacity: 1; }

.Personnage .SpeechDisplayer {
  /* border: 1px solid  red; */
  transition: transform 180ms;
}
.Personnage.hidden .SpeechDisplayer { transform: translateX(-100px);}
.Personnage.visible .SpeechDisplayer { transform: translateX(0);}

.Personnage.on-right .SpeechDisplayer {
  transform: scaleX(-1);
  right: 0;

}


/** DIAL ************************************/

.Personnage .dial-overlay {
  pointer-events: auto;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
}

.Personnage .dial {
  position: absolute;
  left: 400px;
  right: 400px;
  bottom: 25px;
  min-height: 50px;

text-align: center;
}

.Personnage.on-right .dial {
  /* right: 400px;
  left: 60px; */

  /* text-align: right; */
}


.Personnage .dial-character {
  display: inline-block;
  /* border: 1px solid  red; */
  font-size: 28pt;
  font-weight: bold;
  padding: 3px 25px;
  background: #E3E7E8;
  color: var(--almost-black);


  position: absolute;
  bottom: 100%;
  left: 0;
  z-index: 10;

  text-transform: uppercase;
  margin-bottom: -1px;
}




.Personnage .dial-character:before {
  content: '';
  position: absolute;
  left: 100%;
  top: 0;
  bottom: 0;
  aspect-ratio: 1 / 1;


  z-index: -1;
  /* background: red; */
  background: #E3E7E8;

  transform: skewX(45deg) translateX(-50%);
}



.Personnage.on-right .dial-character {
  position: absolute;
  right: 0;
  left: auto;
  bottom: 100%;
}

.Personnage.on-right .dial-character:before {
  left: auto;
  right: 100%;
  transform: skewX(-45deg) translateX(50%);
}

.Personnage .dial-text {
  padding: 20px 25px;

  background: #E3E7E8;
  color: var(--almost-black);
  font-size: 22pt;
  pointer-events: auto;

  min-height: 45px;
}





.Personnage .dial-loader {
  position: absolute;
  bottom: 10px;
  left: 10px;
  width: 30px;
  height: 30px;
}
.Personnage .dial-loader .loader{
  width: 100%;
  height: 100%;
  border-color: var(--maincolor);
}