
.screen.ScreenText .col2 .content{
  position: relative;
  margin: auto;
  overflow-y: auto;
  font-size: 26pt;

  /* border: 1px solid red; */
}
.screen.ScreenText.YOUTUBE .col3{
  min-width: 200px;
  max-width: 200px;
  padding: 30px;
  align-items: flex-end;
}
