.Talent.clickable:hover {
  cursor: pointer;
  filter: brightness(1.2);
}
.Talent .Talent-base{
  fill: #000;
  fill-opacity: 0.3;
}

.Talent.highlighted{
  stroke: #fff;
  stroke-width: 10px;
  stroke-linecap: round;
  stroke-linejoin: round;
  paint-order: stroke;
}


.Talent .cls-2,
.Talent .cls-3,
.Talent .cls-4,
.Talent .cls-5
{
  pointer-events: none;
}

.Talent .internal .cls-1{fill:url(#Dégradé_sans_nom_6);}
.Talent .internal .cls-2{fill:url(#Dégradé_sans_nom_5);}
.Talent .internal .cls-3{fill:url(#Dégradé_sans_nom_5-2);}
.Talent .internal .cls-4{fill:url(#Dégradé_sans_nom_4);}
.Talent .internal .cls-5{fill:url(#Dégradé_sans_nom_4-2);}
.Talent .internal .cls-6{fill:url(#Dégradé_sans_nom_3);}


.Talent .external .cls-1{fill:url(#Dégradé_sans_nom_2);}
.Talent .external .cls-2{fill:url(#Dégradé_sans_nom_213);}
.Talent .external .cls-3{fill:url(#Dégradé_sans_nom_216);}


.Talent.blink {
  animation: talent_blink infinite 1.75s;
}



.Talent.blink:nth-child(2) { animation-delay: 0.25s; }
.Talent.blink:nth-child(4) { animation-delay: 0.25s; }

.Talent.blink:nth-child(3) { animation-delay: 0.5s; }
.Talent.blink:nth-child(5) { animation-delay: 0.5s; }
.Talent.blink:nth-child(7) { animation-delay: 0.5s; }

.Talent.blink:nth-child(6) { animation-delay: 0.75s; }
.Talent.blink:nth-child(8) { animation-delay: 0.75s; }

.Talent.blink:nth-child(9) { animation-delay: 1s; }




@keyframes talent_blink {
  0%  { filter: brightness(1); }
  10% { filter: brightness(1); }
  /* 15% { filter: brightness(1.3);  } */
  20% { filter: brightness(1);  }
  /* 25% { filter: brightness(1); } */
  30% { filter: brightness(1.3);  }
  /* 35% { filter: brightness(1); } */
  40% { filter: brightness(1); }
  /* 45% { filter: brightness(1.3);  } */
  50% { filter: brightness(1); }
  100% { filter: brightness(1); }
}