.ActionsMatrix {

  height: 100%;
  margin: 40px;
  margin-left: 0;
  display: flex;
  align-items: center;
}


.ActionsMatrix.dimAllButOrdonnee .vertical-matrix-container { opacity: 0.4; }
.ActionsMatrix.dimAllButAbscisse .ordonnee{ opacity: 0.4; }
.ActionsMatrix.dimAllButAbscisse .Matrix{ opacity: 0.4; }


.Matrix-legend {
  font-size: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: center;

}
.Matrix-legend > :nth-child(2){
  font-size: 25px;
  color: var(--lightblue);
  opacity: 0.6;
}

.Matrix-legend.ordonnee {
  max-width: 150px;
  margin-bottom: 150px;
  /* text-align: center; */
}
.Matrix-legend.ordonnee.ontop {
  position: relative;
  z-index: 5;
}

.Matrix-legend.abscisse.ontop {
  position: relative;
  z-index: 5;
}
.Matrix-legend.abscisse {
  margin: auto;
  margin-top: 10px;
  max-width: 70%;
}

/* gestion de la taille des fonts */
.ActionsMatrix .Matrix-legend.ordonnee { max-width: 200px;}
.ActionsMatrix .Matrix-legend { font-size: 50px; }
.ActionsMatrix .Matrix-legend  > :nth-child(2){ font-size: 35px; }

.vertical-matrix-container {
  flex-grow: 1;
  height: 100%;

  display: flex;
  flex-direction: column;

}
.Matrix {
  flex-grow: 1;
  border-left: 4px solid var(--lightblue-transparent);
  border-bottom: 4px solid var(--lightblue-transparent);
  position: relative;
  /* border: 1px solid red; */
}

.Matrix::before,
.Matrix::after {
  content: "";
  background: url('../../../images/arrow-right.png') no-repeat center center;
  background-size: contain;
  width: 30px;
  height: 40px;
  position: absolute;
  left: 100%;
  bottom: -1px;
  opacity: 0.5;
  transform: translateY(50%);
}

.Matrix::before {
  background-image: url('../../../images/arrow-top.png');
  height: 30px;
  width: 40px;
  left: -1px;
  bottom: 100%;
  transform: translateX(-50%);
}


.Matrix-content {
  position: absolute;

  top: 40px;
  left: 0;
  bottom: 0;
  right: 40px;

}

.Matrix-content::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 2px;
  background: var(--lightblue-transparent);
}

.Matrix-content::after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  bottom: 0;
  width: 2px;
  background: var(--lightblue-transparent);
}


.Matrix-content-grid-horizontal,
.Matrix-content-grid-vertical {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: space-evenly;
}

.Matrix-content-grid-horizontal {
  flex-direction: column;
}

.Matrix-content-grid-vertical > div {
  height: 100%;
  width: 1px;
  background-image: linear-gradient(rgba(173, 216, 230, 0.4) 3px, transparent 0%);
  background-position: right;
  background-size: 1px 20px ;
  background-repeat: repeat-y;
}
.Matrix-content-grid-horizontal > div {
  height: 1px;
  background-image: linear-gradient(to right, rgba(173, 216, 230, 0.4) 3px, transparent 0%);
  background-position: bottom;
  background-size: 20px 1px;
  background-repeat: repeat-x;
}


.Matrix-action {
  /* padding: 5px 15px; */
  --size: 60px;
  width: var(--size);
  height: var(--size);
  flex-shrink: 0;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 35px;
  color: #000033;
  font-weight: bold;
  position: absolute;
  background: var(--lightblue);
  box-shadow: 0 0 20px var(--lightblue);

  transform: translateX(-50%)  translateY(-50%);

  cursor: pointer;
}

.Matrix-action.inactive {
  opacity: .5;
  box-shadow: none;
  cursor: auto;
}


.Matrix-action.visible {
  --size: 90px;

  font-size: 65px;
  padding: 5px 25px;
  background: #FFF;
  z-index: 1;
}

.Matrix-action.hide {
  opacity: 0;
}

.Matrix-action.SELECTED {
  background: #FFF;
}
.Matrix-action.SELECTED::before{
  content: "";
  background: url('../../../images/check-dark.png') var(--lightgreen) no-repeat center center;
  background-size: 60%;
  width: 40px;
  height: 40px;
  border-radius: 50%;

  position: absolute;
  top: 0;
  left: 100%;
  transform: translateX(-50%)  translateY(-50%);
}

.Matrix .Action-trait{
  position: absolute;

  right: -105px;
  height: 5px;
  background: #FFF;
  transform: translateX(45px);
}


.matrix-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 4;
}