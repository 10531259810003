.BudgetProgressbar {

}
.BudgetProgressbar .BudgetProgressbar-elems-ctnr{

  width: 40px;

  /* background: rgba(32, 189, 103, 0.164); */
  height: 750px;
  /* border: 1px solid  red; */
  position: relative;

  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  gap: 8px;

  margin: 0 auto;
  margin-bottom: 30px;



}
.BudgetProgressbar .BudgetProgressbar-elems-ctnr .BudgetProgressbar-elem{

  /* height: 25px; */
  height: 100%;
  border: 1px solid lime;
  width: 100%;
  background: var(--lightgreen);
  /* margin-top: 40%; */
  transform: skewY(-30deg);
}
.BudgetProgressbar .BudgetProgressbar-elems-ctnr .BudgetProgressbar-elem.inactive{ opacity: 0.4; }



.BudgetProgressbar .BudgetProgressbar-total {
  text-align: center;
  text-transform: uppercase;
  font-size: 30pt;
  color: var(--lightblue);
  opacity: 0.4;
  margin-bottom: 20px;
}
.BudgetProgressbar .BudgetProgressbar-current {
  position: absolute;
  color: var(--lightgreen);
  text-transform: uppercase;
  font-size: 35pt;
  left: -15px;
  transform: translateX(-100%) translateY(50%);

}


.BudgetProgressbar .BudgetProgressbar-title{
  color: var(--lightgreen);
  text-transform: uppercase;
  font-size: 30pt;
}
