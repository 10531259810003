.Brick.clickable { cursor: pointer; }
.Brick.clickable:hover { filter: brightness(1.2); }




.Brick image{
  filter:brightness(100);
  pointer-events: none;
}

.Brick-base {
  fill: #000;
  fill-opacity: 0.3;
  cursor: auto;
}

.Brick-walls {
  cursor: pointer;
}
.Brick-side, .Brick-front {
  pointer-events: none;
}



.Brick .infobulle {
  font-size: 10px;
  color: #00b8bc;
  text-align: center;
  background: #D7F4FF;
  border-radius: 3px;
  position: relative;
}
.Brick .infobulle::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translateX(-50%) ;
  width: 10px;
  height: 10px;
  background: pink;
}

.Brick.strategy .Brick-front { fill: #0f8eceff; }
.Brick.strategy .Brick-side { fill: #175ac6ff; }
.Brick.strategy .Brick-top   { fill: #10a3ffff; }

.Brick.tech .Brick-front { fill: #b123c9ff; }
.Brick.tech .Brick-side { fill: #911d91ff; }
.Brick.tech .Brick-top   { fill: #e629ffff; }

.Brick.people .Brick-front { fill: #f2a138ff; }
.Brick.people .Brick-side { fill: #cf8517ff; }
.Brick.people .Brick-top   { fill: #ffd129ff; }

.blink .Brick .Brick-front { fill: #37b86a; }
.blink .Brick .Brick-side  { fill: #298b50; }
.blink .Brick .Brick-top   { fill: #4CFF95; }

.blink .Brick{
  animation: blink infinite 2s linear;
  pointer-events: none;
}


@keyframes blink {
  0%  { opacity: 0;}
  10% { opacity: 0;}
  15% { opacity: 1;}
  20% { opacity: 0;}
  25% { opacity: 0;}
  30% { opacity: 1;}
  35% { opacity: 0;}
  40% { opacity: 0;}
  45% { opacity: 1;}
  50% { opacity: 0;}
  100% { opacity: 0;}
}
