@font-face {
    font-family: 'Mohave';
    src: url('Mohave-Regular.woff2') format('woff2'),
        url('Mohave-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mohave';
    src: url('Mohave-LightItalic.woff2') format('woff2'),
        url('Mohave-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Mohave';
    src: url('Mohave-Light.woff2') format('woff2'),
        url('Mohave-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mohave';
    src: url('Mohave-MediumItalic.woff2') format('woff2'),
        url('Mohave-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Mohave';
    src: url('Mohave-Bold.woff2') format('woff2'),
        url('Mohave-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mohave';
    src: url('Mohave-Italic.woff2') format('woff2'),
        url('Mohave-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Mohave';
    src: url('Mohave-Medium.woff2') format('woff2'),
        url('Mohave-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mohave';
    src: url('Mohave-BoldItalic.woff2') format('woff2'),
        url('Mohave-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

