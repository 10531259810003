.ActionsGrid {

  display: grid;
  grid-template-columns: repeat(3, 1fr);

  padding: 30px 0;
  padding-right: 20px;
  justify-items: center;

  grid-gap: 20px;
}