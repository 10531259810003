.DashboardPopup-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(12, 38, 74, 0);

  padding: 50px;
}
.DashboardPopup {
  cursor: default;
  background: #0A1232;
  color: #FFF;

  border: 4px solid #FFF;
  box-shadow: 0 0 0 10px #0A1232;
  border-radius: 30px;
  width: 450px;

  position: absolute;
}

.DashboardPopup-overlay.usecase .DashboardPopup {
  left: 900px;
  width: 850px;
  top: 100px;
}
.DashboardPopup-overlay.usecase.headless .DashboardPopup { top: 10px; }

.DashboardPopup-overlay.enabler.strategy .DashboardPopup {
  left: 500px;
  /* top: 70px; */
  top: 200px;
  top: 50%;
  transform:   translateY(-50%);
}
.DashboardPopup-overlay.enabler.tech .DashboardPopup {
  left: 1100px;
  top: 50%;
  transform:   translateY(-30%);
}

.DashboardPopup-overlay.enabler.people .DashboardPopup {
  left: 500px;
  bottom: 10px;
}
.DashboardPopup-overlay.talent .DashboardPopup {
  bottom: 100px;
  left: 1000px;
}

.DashboardPopup .DashboardPopup-close {
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: var(--lightblue);

  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  font-size: 40px;
  font-family: sans-serif;

  top: 5px;
  right: 5px;

  cursor: pointer;
}


.DashboardPopup .DashboardPopup-brick{
  position: absolute;
  top: 0;
  width: 100%;
  transform:  translateY(-50%);

}


.DashboardPopup .DashboardPopup-title {
  font-size: 40px;
  color: #0A1232;
  padding: 10px 20px;
  text-align: center;
  margin: 50px 0;
  margin-top: 70px;
}



.DashboardPopup-talent-content { display: flex; }
.talent .DashboardPopup svg {
  /* border: 1px solid red; */
  flex-shrink: 0;
  width: 200px;
}
.DashboardPopup-talent-content > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.talent .DashboardPopup  .DashboardPopup-title { color: #FFF; }
.talent .DashboardPopup  .DashboardPopup-title,
.talent .DashboardPopup  .DashboardPopup-description {
  text-transform: uppercase;
  margin: 0;

}




.DashboardPopup .DashboardPopup-description {
  color: #00B3BC;
  font-size: 30px;
  padding: 0 50px;
  text-align: center;
  margin-bottom: 30px;
}
.DashboardPopup .DashboardPopup-enabler-detail {
  margin-top: 50px;
  display: flex;
  gap: 20px;
  justify-content: center;
  margin: 50px 0;
}

.DashboardPopup .DashboardPopup-enabler-detail .detail-block{
  display: flex;
  gap: 20px;
  align-items: center;
}

.DashboardPopup .DashboardPopup-enabler-detail .detail-icon{
  width: 70px;
}
.DashboardPopup .DashboardPopup-enabler-detail .detail-l1{
  text-transform: uppercase;
  font-size: 25px;
}
.DashboardPopup .DashboardPopup-enabler-detail .detail-l2{
  font-size: 40px;
  max-width: 200px;
}

.DashboardPopup .DashboardPopup-usecase-detail {
  font-size: 30px;
  margin-top: 50px;
  text-align: center;
  margin-bottom: 30px;
}
.DashboardPopup .DashboardPopup-usecase-svgs {
  display: flex;
  /* border: 1px solid red; */
  justify-content: space-between;
  align-items: flex-end;
  gap: 10px;
  margin-top: 30px;
  padding: 0 20px;
}

.DashboardPopup .DashboardPopup-usecase-svgs > div.arrows{
  width: 50px;
}
.DashboardPopup .DashboardPopup-usecase-svgs > div:not(.arrows){
  /* flex-grow: 1; */
  width: 25%;
  flex-shrink: 0;
  /* border: 1px solid red; */
}
.DashboardPopup .DashboardPopup-usecase-svgs > div:not(.arrows) > div{
  /* font-size: 20px; */
  /* border: 1px solid red; */
  min-height: 90px;
}
.DashboardPopup .DashboardPopup-usecase-svgs .arrows{
  padding-bottom: 90px;
}

.DashboardPopup .DashboardPopup-usecase-svgs svg{
  /* border: 1px solid lime; */
}

.DashboardPopup .Cylinder-beam-ctnr{ display: none; }