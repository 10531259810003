.MomentButton {
  background: none;
  width: 100%;
  max-width: 750px;
  border: 1px solid #08EFFF;
  color: #08EFFF;
  display: flex;



  margin: 0 auto;
  /* margin-left: 150px; */
  margin-bottom: 20px;
  font-size: 18pt;
  cursor: pointer;

  padding: 0;
  align-items: stretch;
  transform: translateX(-80px);
  position: relative;

}

.MomentButton .moment-title{
  flex-grow: 1;
  padding: 50px 0;
  text-align: center;
}
.MomentButton .moment-score{
  min-width: 170px;
  display: flex;
  justify-content: center;
  align-items: center;

  border-left: 1px solid #08EFFF;
  background: rgba(8, 239, 255, 0.2);
}

.MomentButton .moment-score span{
  font-size: 16pt;
  text-align: center;
  text-transform: uppercase;
}

.MomentButton.playable .moment-score img { filter: drop-shadow(0 0 5px#08EFFF); }

/*** LOCKED ****/

.MomentButton.locked {
  border: 1px solid rgba(8, 239, 255, 0.2);
  color: rgba(8, 239, 255, 0.2);
  cursor: auto;
}
.MomentButton.locked .moment-score{
  border-left: 1px solid rgba(8, 239, 255, 0.2);
  color: rgba(8, 239, 255, 1);

  background: rgba(8, 239, 255, 0.05);
}
.MomentButton.locked .moment-score img{
  display: block;
  margin: 0 auto;
  margin-bottom: 10px;
}



.MomentButton.selected,
.MomentButton:enabled:hover{
  border-width: 6px;
  max-width: 800px;
  filter: drop-shadow(0 0 5px#08EFFF);

  transition: max-width 0.15s, font-size 0.15s;

}

.MomentButton.selected .moment-title,
.MomentButton:enabled:hover .moment-title{
  background: #08EFFF;
  color: #151621;
  font-size: 21pt;
  font-weight: bold;
}



.MomentButton .moment-medals {
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 10px;
}

.MomentButton .moment-medal {
  display: flex;
  align-items: center;
  gap: 5px;
}



.MomentButton .moment-play-btn {
  position: absolute;
  left: 100%;

  margin-left: 20px;
  top: 50%;
  transform:  translateY(-50%);
}


.MomentButton .moment-play-btn:hover .img-ctnr{
  background-position: bottom left;
}
.MomentButton .moment-play-btn .img-ctnr{
  width: 34px;
  height: 40px;
  margin-right: 20px;
  background: url('../../../images/moment_selection/playbtn.png') no-repeat;
  background-position: top left;
}

.MomentButton .moment-play-btn .wait-spinner{
  min-width: 35px;
  min-height: 35px;
  max-width: 35px;
  max-height: 35px;
  border-radius: 50%;

  border: 4px solid var(--lightblue);
  border-bottom: none;
  border-right: none;

  margin-right: 20px;

  animation : rotate linear 1s infinite;


}

@keyframes rotate {
 0% {transform: rotate(0);}
 100% {transform: rotate(1turn);}

}