.MomentRightColumn {
  max-height: 100%;
  overflow-y: auto;
  margin-bottom: 20px;
  padding-right: 10px;
}
.MomentRightColumn section{
  margin: 30px 0;
}
.MomentRightColumn h3{
  text-transform: uppercase;
  font-size: 30pt;
  margin: 0;
  margin-bottom: 10px;
  font-weight: 500;
  text-align: center;
}

/* .MomentRightColumn .enablers { color: var(--enablers); } */
.MomentRightColumn .usecases { color: var(--usecases); }

.MomentRightColumn .list {
  justify-content: flex-start;
  flex-wrap: wrap;
}

.MomentRightColumn .list > * {
  /* width: 50%; */
  justify-content: flex-start;
  /* gap: 10px; */
}
.MomentRightColumn .Enabler-line {
  display: flex;
  align-items: center;
  padding-right: 10px;
  gap: 10px;
  position: relative;
  margin-bottom: 10px;
}

.MomentRightColumn .Enabler-line svg{
  min-width: 120px;
  width: 120px;
  height: 80px;

  max-width: none;
}

.MomentRightColumn .Enabler-line.highlighted svg.green {
  position: absolute;
  stroke: #00FF6D;
  stroke-width: 6px;
  stroke-linecap: round;
  stroke-linejoin: round;
  paint-order: stroke;
  z-index: 0;
}

.MomentRightColumn .Enabler-line.highlighted::after {
  content: "+";
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  width: 25px;
  height: 25px;
  font-size: 18pt;
  line-height: 15pt;
  background: #00FF6D;
  color: #000;

}
.MomentRightColumn .Enabler-line.highlighted svg { position: relative; }


.MomentRightColumn .Enabler-line .Enabler-line-title {
  font-size: 18pt;
 }



.Dropdown .Dropdown-header{
  background: rgba(70, 197, 240, 0.3);
  border-radius: 15px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  gap: 15px;
  font-size: 17pt;
}

.Dropdown .Dropdown-icon{
  min-width: 40px;
  max-width: 40px;
  min-height: 30px;
  max-height: 30px;
  background: pink;
}
.Dropdown.tech .Dropdown-icon{
  background: url('../../../images/tech&data.png') no-repeat center center;
  background-size: contain;
}
.Dropdown.strategy .Dropdown-icon{
  background: url('../../../images/strat&gov.png') no-repeat center center;
  background-size: contain;
}
.Dropdown.people .Dropdown-icon{
  background: url('../../../images/people&wow.png') no-repeat center center;
  background-size: contain;
}
.Dropdown .Dropdown-title{ flex-grow: 1; }