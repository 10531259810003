.ActionCard {
  position: relative;

  min-width: 330px;
  max-width: 330px;
  min-height: 260px;
  max-height: 260px;
  border-top: none;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;

  --title-bg: #FFF;
  color: var(--title-bg);

  transform-style: preserve-3d;

  transition: transform 0.8s, opacity 0.3s;
}

.ActionCard .front,
.ActionCard .back{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  padding: 8px;

  overflow: hidden;
  backface-visibility: hidden;
  -moz-backface-visibility: hidden;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  border-radius: 35px;
  background: #0B4465;
}


.ActionCard .front > *,
.ActionCard .back > *  { padding: 5px 15px; }


.ActionCard .ActionCard-body{
  border: 3px solid inherit;
  border-radius: 30px;
  padding: 10px;

  flex-grow: 1;
  overflow: hidden;
  border: 2px solid var(--title-bg);

  display: flex;
  flex-direction: column;
  justify-content: space-around;
}


.ActionCard .ActionCard-icon {
  display: flex;
  justify-content: center;
  margin-bottom: 14px;
}
.ActionCard .ActionCard-icon svg{ height: 54px; }
.ActionCard .ActionCard-icon.usecase svg{ height: 90px; }

.ActionCard.flipped{ transform: rotateY(180deg); }

.ActionCard .back{ transform: rotateY( 180deg ); }

/* types */
.ActionCard.usecase { --title-bg: var(--usecases); }
.ActionCard.talent { --title-bg: var(--talents); }
.ActionCard.enabler { --title-bg: var(--enablers); }

.ActionCard.enabler.strategy { --title-bg: var(--enablers-strategy); }
.ActionCard.enabler.tech { --title-bg: var(--enablers-tech); }
.ActionCard.enabler.people { --title-bg: var(--enablers-people); }

.ActionCard:not(.selected){ cursor: pointer; }

/* .ActionCard.selected .front{ border: 1px solid  red; } */
.ActionCard.locked { border-color: #888; }

.ActionCard.prev-selected { --title-bg: var(--lightgreen); border: none;}

.ActionCard.prev-selected > *{
  opacity: 0.4;
  border: 1px solid  var(--lightgreen);
}
/* .ActionCard.too-expensive, */
.ActionCard.locked > *{
  opacity: 0.4;
  filter: grayscale(1);
}

.ActionCard .ActionCard-close {
  position: absolute;
  top: 10px;
  right: 10px;
  transform: translateX(50%)  translateY(-50%);
  z-index: 1;
  width: 45px;
  height: 45px;

  padding: 0;


  border-radius: 50%;
  background: #38FF7E;

  display: flex;
  justify-content: center;
  align-items: center;
  color: #10323B;
  font-size: 50px;
  font-weight: bold;
  line-height: 0px;
  font-family: sans-serif;
  cursor: pointer;


  backface-visibility: hidden;
  -moz-backface-visibility: hidden;
}
.ActionCard .ActionCard-close:hover {
  width: 55px;
  height: 55px;
  font-size: 60px;
}

.ActionCard .ActionCard-type {
  background: var(--title-bg);
  color: #000;
  font-size: 15pt;
  font-weight: 500;
}



.ActionCard .ActionCard-title {
  /* font-size: 19pt; */
  line-height: 1.1em;
  font-weight: 300;
  color: #FFF;
  text-align: left;

  overflow-y: auto;
  font-size: 5px;

  display: flex;
  justify-content: center;
  align-items: center;
  /* flex-grow: 1; */
  height: 140px;
  background-repeat: repeat-y;
}

.ActionCard .ActionCard-description{
  margin-bottom: 15px;
  padding: 0 10px;
  font-size: 17pt;
  overflow-x: hidden;
  flex-grow: 1;
  color: #FFF;
}

.ActionCard .ActionCard-cost {
  text-align: center;
  font-size: 20pt;
  color: #FFF;
  line-height: 1em;

  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;

  margin-top: 10px;

}
.ActionCard .ActionCard-cost-value {
  font-size: 42pt;
  font-weight: 500;
  line-height: 0.9em;
}


.ActionCard .ActionCard-pill {
  margin: 5px auto;
  align-self: center;
  font-size: 30pt;
  font-weight: 500;
  text-transform: uppercase;
  background: var(--title-bg);
  color: #000;
  padding: 5px 50px;
  border-radius: 999px;
  text-align: center;
}

.ActionCard.too-expensive .ActionCard-pill {
  font-size: 25pt;
}

.ActionCard .ActionCard-contour {
  position: absolute;
  pointer-events: none;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  border: 6px solid  #38FF7E;
  border-radius: 37px;
  /* z-index: 10; */
  /* box-shadow: 0 0 10px #38FF7E; */
}
