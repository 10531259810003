.ScreenDashboard {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ScreenDashboard .ScreenDashboard-title {
  position: absolute;
  top: 20px;
  left: 20px;
}

.ScreenDashboard .ScreenDashboard-nextbtn{
  position: absolute;

  bottom: 20px;
  right: 20px;
}
