* {box-sizing: border-box;}

html, body, #root, .App {
  margin: 0;
  padding: 0;
  overflow: hidden;
  user-select: none;

  height: 100%;
}

:root{
  --lightblue: #08EFFF;
  --lightblue-transparent: rgba(8, 239, 255, 0.5);

  --lightgreen: #00FF6D;
  --enablers: #FFE552;
  --enablers-tech: #E629FF;
  --enablers-strategy: #10A3FF;
  --enablers-people: #FFD129;
  --fake-enablers: #701212;
  --failed: #FF4F6F;
  --talents: #61A3FF;
  --usecases: #88ffe5;
  --almost-black: #10323B;
  --value-bar-color: red; /* la couleur par défaut des ValueBar */
}


body {
  background-size: cover;

  background:
  /* url('../../images/bg.png') no-repeat center -150px, */
  radial-gradient(circle at 64.48% 5.94%, #0A4E6E, transparent 30%),
  radial-gradient(circle at 1.41% 40.43%, #0B4B6C, transparent 50%),
  radial-gradient(circle at 93.44% 86.9%, #0B4B6C, transparent 16%),
  radial-gradient(circle at 29.38% 96.83%, #4C1A57, transparent 47%),
  radial-gradient(circle at 50% 50%, #0c1f43, #0c1f43 100%);
  background-size: contain;
  color: #FFF;
  font-family: "Mohave", sans-serif;
}

img {
  max-width: 100%;
}

.App { height: 100%; }


.App * {
  white-space: pre-wrap;
  font-family: inherit;
}

.screen {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  display: flex;
}

.screen .col1{
  min-width: 420px;
  max-width: 420px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

}
.screen .col2 {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  overflow: hidden;

}
.screen .col3{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-items: center; */
  width: 380px;
  flex-shrink: 0;
  padding: 30px;

}

.list{
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.list.vertical{
  flex-direction: column;
  justify-content: flex-end;
  gap: 10px;
}

.App .version {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 5px;
  font-size: 10px;
}
.App .dials-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);

  display: flex;
  align-items: flex-end;
}
.App .dials {
  display: flex;
  align-items: flex-end;
  color: #000;
}

.App .dials .character {
  opacity: 1;
  min-width: 350px;
  height: 500px;
  background: url('../../images/perso.png') no-repeat;
  background-size: contain;

}
.App .dials .dial-name {
  padding: 10px;

  background: #d1d1d1;
  display: inline-block;
  text-transform: uppercase;
  font-weight: bold;
  margin-left: 20px;

}
.App .dials .dial-text {
  padding: 20px;
  background: #d1d1d1;

  cursor: pointer;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;

  max-width: 750px;

}

.App .errors {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 50%;
}

.App .errors .error{
  background: pink;
  color: red;
  padding: 5px;
  border: 1px solid  red;
  border-radius: 5px;
  margin: 5px;
}


* {
  scrollbar-width: thin;
  scrollbar-color: var(--lightblue) #1a1941;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: rgba(8, 239, 255, 0.31);
  overflow: visible;
}

::-webkit-scrollbar-thumb {
  background: var(--lightblue);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #44a2a3;

}