.DashboardFtue-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(12, 38, 74, 0.9);
}
.DashboardFtue {

  position: absolute;
  border: 3px solid #FFF;
  overflow: hidden;
  border-radius: 30px;
  display: flex;
}
.DashboardFtue .DashboardFtue-content {
  padding: 20px;
  /* background: #0c264a; */
  max-width: 600px;
}
.DashboardFtue .DashboardFtue-title {
  color: #FFF;
  font-size: 50px;
}
.DashboardFtue .DashboardFtue-subtitle {
  color: #00B3BC;
  font-size: 30px;
}



.DashboardFtue.enablers {

  /* top: 397px; */
  /* left: 658px; */
  /* right: 545px; */
}

.DashboardFtue.usecases {
  flex-direction: row-reverse;
  top: 408px;
  /* left: 658px; */
  left: 522px;
}

.DashboardFtue .DashboardFtue-elements{
  /* width: 200px; */
  flex-shrink: 0;
}
.DashboardFtue.usecases  .DashboardFtue-elements{
  width: 300px;
}

.DashboardFtue-overlay .bb {
  position: absolute;
  border: 4px solid red;
}
