.SpeechDisplayer{
  display: block;
  height: auto;

  position: absolute;
  bottom: 0;

  display: flex;
  align-items: flex-end;
}

.SpeechDisplayer canvas{
  display: block;
  max-height: 600px;
  height: 80%;
}


@media screen and (max-width: 1100px) {
  .SpeechDisplayer canvas{
    /* transform: translateX(-80px); */
    /* max-height: 500px; */
  }
}

@media screen and (max-width: 600px) {
  .SpeechDisplayer canvas{
    /* max-height: 400px; */
  }
}
@media screen and (max-height: 850px) {
  .SpeechDisplayer canvas{
    /* height: 50%; */
    /* max-height: 350px; */
  }
}