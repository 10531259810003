.NeonButton {
  border: 6px solid #08EFFF;
  background: none;
  font-size: 20pt;
  display: inline-block;

  color: #08EFFF;
  text-transform: uppercase;

  cursor: pointer;

  position: relative;

  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 25px 30px;
  border-radius: 20px;

  filter: drop-shadow(0 0 5px#08EFFF);

}

.NeonButton:hover {
  background: #08EFFF;
  color: #151621;
}
.NeonButton:disabled {
  filter: none;
  pointer-events: none;
  opacity: 0.3;
  /* background: transparent;
  color: #08EFFF; */
}

.NeonButton.small {
  padding: 10px 15px;
  font-size: 15pt;
  border-width: 4px;
}