.MatrixActionDescription {

  border: 4px solid #00ffff7e;
  height: 100%;
  margin: 30px 0;
  border-radius: 30px;

  display: flex;
  padding: 50px;
  /* padding-top: 80px; */

}
.MatrixActionDescription.selected {
  background: rgba(0, 255, 255, 0.1);
  border-color: #FFF;
}

.MatrixActionDescription .MatrixActionDescription-empty{
  font-size: 30px;
  margin: auto;
}

.MatrixActionDescription .MatrixActionDescription-uc svg{
  /* border: 1px solid red; */
  width: 100%;
}
.MatrixActionDescription .MatrixActionDescription-uc{
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-between;
}

.MatrixActionDescription .MatrixActionDescription-uc .uc-title{
  font-size: 45px;

}
.MatrixActionDescription .MatrixActionDescription-uc .uc-description{
  font-size: 30px;
  color: var(--lightblue);

}

.MatrixActionDescription .MatrixActionDescription-uc .uc-button{
  text-align: center;
}
.MatrixActionDescription .MatrixActionDescription-uc .uc-button.discarded .NeonButton{
  /* border-color: #08efff50; */
  border-style: dashed;
}