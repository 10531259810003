.Rails {
  stroke-dasharray: 2, 2;
  stroke-opacity: 1;
  stroke-width: 0.7;
  fill: none;



  stroke-dashoffset: -80;
  animation: dash 5s linear forwards infinite;
}

@keyframes dash {
  to { stroke-dashoffset: 0; }
}
