
.ScreenFinalDebrief .ScreenFinalDebrief-title{
  /* border: 1px solid lime; */
  display: flex;
  align-items: flex-start;
}
.ScreenFinalDebrief .col2{

  display: flex;
  flex-direction: column;



}

.ScreenFinalDebrief h3{ font-size: 40pt; }

.ScreenFinalDebrief .col2-bottom  {
  margin: 20px;
  margin-bottom: 50px;

  /* border: 1px solid yellow; */
  display: flex;
  justify-content: space-between;

  flex-grow: 1;
}


.ScreenFinalDebrief .col2-bottom  > div{

  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ScreenFinalDebrief .content{
  font-size: 18pt;
  margin: auto;
  overflow-y: auto;
  font-size: 18pt;
  padding: 0 20px;
}


.ScreenFinalDebrief .fd-moment {
  padding: 5px 0;
  display: flex;
  align-items: center;
  gap: 30px;
  font-size: 25pt;
}
.ScreenFinalDebrief .fd-moment .m-title{ flex-grow: 1; }
.ScreenFinalDebrief .fd-moment img { width: 90px;}


.ScreenFinalDebrief .ebit-value{
  background: #174D77;
  background: linear-gradient(to bottom, #174D771f, #174D7799);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 0 auto;

  width: 350px;
  height: 350px;
  border-radius: 50%;

  position: relative;
}

.ScreenFinalDebrief .ebit-value-current{
  font-size: 85pt;
  line-height: 85pt;

}
.ScreenFinalDebrief .ebit-value-separator {
  width: 30px;
  height: 5px;
  background: #FFF;
}
.ScreenFinalDebrief .ebit-value-total{
  font-size: 45pt;
  line-height: 45pt;
  color: #08EFFF;
}

/* la bordure bleue claire */
.ScreenFinalDebrief .ebit-value::after{
  content: "";
  position: absolute;
  top: 8px;
  left: 8px;
  right: 8px;
  bottom: 8px;
  border-radius: 50%;
  border: 2px solid #08EFFF;
}
