.FadedScroll-ctnr {
  position: relative;
  /* border: 1px solid  red; */
  height: 100%;
  flex-grow: 1;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.FadedScroll-ctnr::before,
.FadedScroll-ctnr::after {
  content: "";
  flex-grow: 1;
}

.FadedScroll {
  overflow-y: auto;
  flex-grow: 1;

  touch-action: manipulation;
}

.FadedScroll-overlay-top,
.FadedScroll-overlay-bottom{
  position: absolute;
  top: 0;
  left: 0;
  right: 5px;
  height: 40px;
  background: linear-gradient(to bottom, #0C1F43 20%, transparent);
  z-index: 2;
  pointer-events: none;
}

.FadedScroll-overlay-bottom{
  top: auto;
  bottom: 0;
  background: linear-gradient(to top, #0C1F43 20%, transparent);
}