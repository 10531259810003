.GamemasterOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;


  background:  rgba(0, 0, 0, 0.7);

  background: linear-gradient(to right, transparent, rgba(12, 31, 67, 0.9) 30%, rgba(12, 31, 67, 0.9) 60%, transparent);

  background: rgba(12, 31, 67, 0.5);
  font-size: 40px;

  z-index: 10;

}

.GamemasterOverlay span {

  background: rgba(12, 31, 67, 1);
  padding: 20px 40px;

}
