.Circle .Circle-base{
  fill: #0094a1;
  fill-opacity: 0.2;
}
.Circle .Circle-stroke{
  stroke: #00b8bc;
  fill: none;
  stroke-dasharray: 1.44712, 1.44712;
  stroke-linejoin: round;
  stroke-width: 0.72356;
}



/* utilisé https://codepen.io/sosuke/pen/Pjoqqp pour les couleurs des icones */
.SvgDashboard .Cylinder image {
  filter: brightness(0) invert(16%) sepia(22%) saturate(3652%) hue-rotate(156deg) brightness(92%) contrast(98%);
  /* color: #022229; */
}
.SvgDashboard .Cylinder .RED image {
  filter: brightness(0) invert(16%) sepia(29%) saturate(1334%) hue-rotate(317deg) brightness(99%) contrast(86%);
}

.SvgDashboard .Cylinder .GREEN image {
  filter: brightness(0) invert(20%) sepia(50%) saturate(2386%) hue-rotate(123deg) brightness(91%) contrast(77%);
  /* color: #1d6138; */
}

.SvgDashboard .Circle .blink .Cylinder-stage0 {
  animation: base_blink infinite 2s;
}
.SvgDashboard .Circle .blink.GREEN .Cylinder-side-icons {
  animation: icon_blink infinite 2s;
}
.SvgDashboard .Circle .blink.RED .Cylinder-side-icons {
  animation: icon_blink infinite 2s;
}

@keyframes base_blink {
  0%  { fill: #4CFF95;}
  10% { fill: #4CFF95;}
  15% { fill: #FFF;}
  20% { fill: #4CFF95;}
  25% { fill: #4CFF95;}
  30% { fill: #fff;}
  35% { fill: #4CFF95;}
  40% { fill: #4CFF95;}
  45% { fill: #fff;}
  50% { fill: #4CFF95;}
  100% { fill: #4CFF95;}
}

@keyframes icon_blink {
  0%  { opacity: 0.6; }
  10% { opacity: 0.6; }
  15% { opacity: 1;  }
  20% { opacity: 0.6;  }
  25% { opacity: 0.6; }
  30% { opacity: 1;  }
  35% { opacity: 0.6; }
  40% { opacity: 0.6; }
  45% { opacity: 1;  }
  50% { opacity: 0.6; }
  100% { opacity: 0.6; }
}

@keyframes icon_slide {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  60% {
    /* transform: translateY(-5px); */
    opacity: 1;
  }
  100% {
    transform: translateY(-5px);
    opacity: 0;
  }
  /* 100% {
    transform: translateY(0);
    opacity: 1;
  } */
}