.MediasGallery {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background:#0c1f4371;

  z-index: 8;

  display: flex;
  justify-content: center;
  align-items: center;
}
.MediasGallery .MediasGallery-close ,
.MediasGallery .MediasGallery-prev,
.MediasGallery .MediasGallery-next {
  position: absolute;
  top: 45px;
  right: 45px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;
  background: var(--lightgreen);
  color: #10323B;
  font-size: 50px;
  font-weight: bold;
  line-height: 0px;
  font-family: sans-serif;
  cursor: pointer;
}
.MediasGallery .MediasGallery-prev:hover,
.MediasGallery .MediasGallery-next:hover,
.MediasGallery .MediasGallery-close:hover { transform: scale(1.2); }



.MediasGallery .MediasGallery-element {
  /* position: absolute; */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center ;
  width: 90%;
  height: 85%;

}

.MediasGallery .MediasGallery-prev, .MediasGallery .MediasGallery-next {
  top: 50%;
  left: 45px;
  right: auto;
  border: none;

}

.MediasGallery .MediasGallery-next {
  left: auto;
  right: 45px;
}