.DialOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  opacity: 0;

  /* background: rgba(199, 47, 230, 0.589); */
  background: rgba(0, 0, 0, 0.30);
  transition: opacity 0.2s;
}

.DialOverlay.visible {
  opacity: 1;
  pointer-events: auto;
}